<template>
   <v-card elevation="2" width="100%" :loading="isloading" :disabled="!$store.getters.isSuperOrAdmin">
      <Notification type="success" message="Cambios guardados" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card-title>
         Pegaso
         <v-icon small right>mdi-horse-variant-fast</v-icon>
      </v-card-title>
      <v-card-text>
         <form>
            <v-text-field
               v-model="pegaso_username"
               :error-messages="pegasoUsernameErrors"
               label="Nombre de usuario"
               required
               @input="$v.pegaso_username.$touch()"
               @blur="$v.pegaso_username.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="pegaso_pass"
               :error-messages="pegasoPassErrors"
               label="Contraseña"
               required
               @input="$v.pegaso_pass.$touch()"
               @blur="$v.pegaso_pass.$touch()"
               :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
               :type="show_password ? 'text' : 'password'"
               @click:append="show_password = !show_password"
            ></v-text-field>
            <v-select
               v-model="pegaso_type"
               :items="types"
               :error-messages="PegasoTypeErrors"
               label="Tipo"
               required
               @change="$v.pegaso_type.$touch()"
               @blur="$v.pegaso_type.$touch()"
            ></v-select>
            <v-btn class="mr-4" :loading="isloading" @click="submit">Guardar</v-btn>
            <v-btn @click="clear" x-small icon>
               <v-icon dense>mdi-eraser</v-icon>
            </v-btn>
         </form>
      </v-card-text>
   </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Notification from "../Notification.vue";

export default {
   mixins: [validationMixin],
   validations: {
      pegaso_username: { required, maxLength: maxLength(50) },
      pegaso_pass: { required, maxLength: maxLength(50) },
      pegaso_type: { required },
   },
   components: {
      Notification,
   },
   data: () => ({
      pegaso_username: "",
      pegaso_pass: "",
      pegaso_type: "",
      types: ["SMS", "FLASH"],
      show_password: false,
      isloading: false,
   }),
   mounted: function () {
      this.pegaso_username = this.$store.state.perfil.pegaso.pegaso_username;
      this.pegaso_type = this.$store.state.perfil.pegaso.pegaso_type == "S" ? "SMS" : "FLASH";
   },
   computed: {
      pegasoUsernameErrors() {
         const errors = [];
         if (!this.$v.pegaso_username.$dirty) return errors;
         !this.$v.pegaso_username.required && errors.push("Requerido.");
         !this.$v.pegaso_username.maxLength && errors.push("Maximo 50 caracteres");
         return errors;
      },
      pegasoPassErrors() {
         const errors = [];
         if (!this.$v.pegaso_pass.$dirty) return errors;
         !this.$v.pegaso_pass.required && errors.push("Requerido.");
         !this.$v.pegaso_pass.maxLength && errors.push("Maximo 50 caracteres");
         return errors;
      },
      PegasoTypeErrors() {
         const errors = [];
         if (!this.$v.pegaso_type.$dirty) return errors;
         !this.$v.pegaso_type.required && errors.push("Requerido");
         return errors;
      },
   },

   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("updatePegaso", {
                  user: this.pegaso_username,
                  pass: this.pegaso_pass,
                  type: this.pegaso_type == "FLASH" ? "F" : "S",
               });
               if (res == true) {
                  this.$refs.ok_noti.Show();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.pegaso_username = "";
         this.pegaso_pass = "";
         this.pegaso_type = "";
      },
   },
};
</script>
