<template>
   <div style="height: 100%; width: 100%">
      <v-tabs v-model="tab" align-with-title>
         <v-tabs-slider></v-tabs-slider>
         <v-tab>General</v-tab>
         <v-tab>Email</v-tab>
         <v-tab>Pegaso</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
         <v-tab-item>
            <div flat>
               <div class="d-flex align-center ml-2">
                  <v-switch
                     class="mx-2"
                     style="width: 150px"
                     v-model="darkmode"
                     :label="'Tema: ' + (this.darkmode ? 'Dark' : 'Light')"
                     @change="toggleDark"
                     inset
                  ></v-switch>
                  <v-chip v-if="$store.getters.isSuperOrAdmin" small :color="suscripcion_state_style" class="mb-1">{{ suscripcion_state }}</v-chip>
               </div>
               <General />
            </div>
         </v-tab-item>
         <v-tab-item>
            <div flat>
               <Email />
            </div>
         </v-tab-item>
         <v-tab-item>
            <div flat>
               <Pegaso />
            </div>
         </v-tab-item>
      </v-tabs-items>
   </div>
</template>

<script>
import General from "../components/Settings/General.vue";
import Email from "../components/Settings/Email.vue";
import Pegaso from "../components/Settings/Pegaso.vue";
import moment from "moment";

export default {
   name: "Settings",
   components: {
      General,
      Email,
      Pegaso,
   },
   data: () => {
      return {
         darkmode: true,
         tab: null,
         items: ["web", "shopping", "videos", "images", "news"],
         text: "Lorem ",
      };
   },
   methods: {
      toggleDark: function () {
         localStorage.setItem("darkmode", this.darkmode);
         this.$vuetify.theme.dark = this.darkmode;
      },
   },
   mounted: function () {
      if (localStorage.getItem("darkmode") == "false") {
         this.darkmode = false;
      } else {
         localStorage.setItem("darkmode", true);
         this.darkmode = true;
      }
      this.$vuetify.theme.dark = this.darkmode;
   },
   computed: {
      suscripcion_state: function () {
         if (this.$store.getters.isSuperOrAdmin == true) {
            return "Suscripción: " + moment(this.$store.state.perfil.suscripcion).format("DD/MM/YYYY");
         } else {
            return "";
         }
      },
      suscripcion_state_style: function () {
         if (this.$store.getters.isSuperOrAdmin == true) {
            let days_left = moment(this.$store.state.perfil.suscripcion).diff(moment(), 'days');
            if(days_left <= 7){
               return "red";
            } else if(days_left > 7 && days_left <= 30){
               return "orange";
            } else {
               return "green";
            }
         } else {
            return "default";
         }
      },
   },
};
</script>
