<template>
   <v-card elevation="2" class="mb-2 mr-2" style="width: 100%" :loading="isloading">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card-title>
         Perfil
         <v-icon small right>mdi-account</v-icon>
      </v-card-title>
      <v-card-text>
         <form>
            <v-text-field
               v-model="nombre"
               :error-messages="nombreErrors"
               label="Nombre"
               required
               @input="$v.nombre.$touch()"
               @blur="$v.nombre.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="telefono"
               :error-messages="telefonoErrors"
               :counter="10"
               label="Telefono"
               required
               @input="$v.telefono.$touch()"
               @blur="$v.telefono.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="rfc"
               :error-messages="rfcErrors"
               label="RFC"
               required
               @input="$v.rfc.$touch()"
               @blur="$v.rfc.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="email"
               :error-messages="emailErrors"
               label="Email"
               required
               @input="$v.email.$touch()"
               @blur="$v.email.$touch()"
            ></v-text-field>
            <v-text-field
               :disabled="!$store.getters.isSuperOrAdmin"
               v-model="direccion"
               :error-messages="direccionErrors"
               label="Direccion"
               :counter="80"
               @input="$v.direccion.$touch()"
               @blur="$v.direccion.$touch()"
            ></v-text-field>
            <v-text-field
               :disabled="!$store.getters.isSuperOrAdmin"
               v-model="empresa"
               :error-messages="empresaErrors"
               label="Empresa"
               :counter="50"
               @input="$v.empresa.$touch()"
               @blur="$v.empresa.$touch()"
            ></v-text-field>
            <v-btn class="mr-4" @click="submit" :loading="isloading">Guardar</v-btn>
            <v-btn @click="clear" x-small icon>
               <v-icon dense>mdi-eraser</v-icon>
            </v-btn>
         </form>
      </v-card-text>
   </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, minLength, alpha, integer } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         name: function () {
            return /^[a-zA-Z_ ]*$/.test(this.nombre);
         },
      },
      telefono: {
         required,
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
      rfc: { required, maxLength: maxLength(30) },
      email: { required, email, maxLength: maxLength(320) },
      direccion: { dir_required: function(){
         if(this.$store.getters.isSuperOrAdmin){
            if(this.direccion.trim().length > 0){
               return true;
            }
            return false;
         } else {
            return true;
         }
      }, maxLength: maxLength(80) },
      empresa: { maxLength: maxLength(50), required },
   },
   data: () => ({
      nombre: "",
      telefono: "",
      rfc: "",
      email: "",
      direccion: "",
      empresa: "",
      isloading: false,
   }),
   mounted: function () {
      this.nombre = this.$store.state.perfil.nombre;
      this.telefono = this.$store.state.perfil.telefono;
      this.rfc = this.$store.state.perfil.rfc;
      this.email = this.$store.state.perfil.email;
      this.direccion = this.$store.state.perfil.direccion;
      this.empresa = this.$store.state.perfil.empresa;
   },
   computed: {
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.name && errors.push("Debe contener caracteres alfabeticos");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.required && errors.push("El telefono es requerido");
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
      rfcErrors() {
         const errors = [];
         if (!this.$v.rfc.$dirty) return errors;
         !this.$v.rfc.required && errors.push("Required.");
         !this.$v.rfc.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.email && errors.push("Debe ser un email valido");
         !this.$v.email.required && errors.push("El email es requerido");
         !this.$v.email.maxLength && errors.push("El maximo posible es de 320");
         return errors;
      },
      direccionErrors() {
         const errors = [];
         if (!this.$v.direccion.$dirty) return errors;
         !this.$v.direccion.dir_required && errors.push("La direccion es requerida");
         !this.$v.direccion.maxLength && errors.push("Debe contener maximo 80 caracteres");
         return errors;
      },
      empresaErrors() {
         const errors = [];
         if (!this.$v.empresa.$dirty) return errors;
         !this.$v.empresa.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.empresa.required && errors.push("Requerido");
         return errors;
      },
   },
   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("updateProfile", {
                  nombre: this.nombre,
                  telefono: this.telefono,
                  rfc: this.rfc,
                  email: this.email,
                  empresa: this.empresa,
                  direccion: this.direccion,
               });
               if (res == true) {
                  this.$refs.ok_noti.Show();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.nombre = "";
         this.telefono = "";
         this.rfc = "";
         this.email = "";
         this.direccion = "";
         this.empresa = "";
      },
   },
};
</script>
