<template>
   <div class="d-flex flex-column pa-2">
      <div class="d-flex flex-column flex-md-row">
         <Profile />
         <div class="d-flex flex-column">
            <Password />
            <Logo />
         </div>
      </div>
      <Encabezado />
      <ApiKey />
   </div>
</template>

<script>
import Profile from "./General/Profile.vue";
import Password from "./General/Password.vue";
import Logo from "./General/Logo.vue";
import Encabezado from "./General/Encabezado.vue";
import ApiKey from "./General/ApiKey.vue"

export default {
   components: {
      ApiKey,
      Profile,
      Password,
      Logo,
      Encabezado,
   },
};
</script>
