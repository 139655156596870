<template>
   <v-card elevation="2" class="mb-2 mr-2" style="width: 100%" :disabled="!$store.getters.isSuperOrAdmin" :loading="isloading || isloading_img">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog message="¿Eliminar encabezado?" ref="dialog" @result_ok="deleteFile" />
      <v-card-title>
         Encabezado
         <v-icon small right>mdi-format-title</v-icon>
      </v-card-title>
      <div class="pa-4" style="background-color: white">
         <v-img contain :src="img" max-height="500px"></v-img>
      </div>
      <v-card-text>
         <form>
            <v-file-input
               show-size
               truncate-length="35"
               accept="image/*"
               @change="onChange"
               @click:clear="onClear"
               :error-messages="fileErrors"
               label="Cargar imagen"
               @input="$v.file.$touch()"
               @blur="$v.file.$touch()"
            ></v-file-input>
         </form>
      </v-card-text>
      <v-card-actions>
         <v-btn text :disabled="!has_image" :loading="isloading" @click="submit">Guardar</v-btn>
         <v-btn text @click="$refs.dialog.Show()">Eliminar</v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
import Notification from "../../Notification.vue";
import Dialog from "../../Dialog.vue";
import { validationMixin } from "vuelidate";

export default {
   components: {
      Notification,
      Dialog,
   },
   mixins: [validationMixin],
   validations: {
      file: {
         filesize: function () {
            return this.size < 1000000;
         },
      },
   },
   computed: {
      fileErrors() {
         const errors = [];
         if (!this.$v.file.$dirty) return errors;
         !this.$v.file.filesize && errors.push("El tamaño de la imagen es superior al permitido (1mb)");
         return errors;
      },
   },
   data: () => {
      return {
         img: "",
         file: "",
         size: 0,
         has_image: false,
         isloading: false,
         isloading_img: false,
      };
   },
   mounted: function () {
      this.img = this.$store.state.perfil.encabezado;
   },
   methods: {
      onChange(e) {
         try {
            this.file = e;
            this.size = e.size;
            const image = e;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            this.isloading_img = true;
            reader.onload = (e) => {
               this.img = e.target.result;
               this.has_image = true;
               this.isloading_img = false;
            };
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
      onClear() {
         this.has_image = false;
         this.size = 0;
         this.$v.$reset();
      },
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;

               let formData = new FormData();
               formData.append("img", this.file);

               let res = await this.$store.dispatch("updateEncabezado", formData);

               if (res == true) {
                  this.$refs.ok_noti.Show();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
            this.isloading_img = false;
         }
      },
      async deleteFile() {
         try {
            let res = await this.$store.dispatch("deleteEncabezado");
            if (res == true) {
               this.$refs.ok_noti.Show();
               this.img = "";
               this.file = "";
               this.size = 0;
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
   },
};
</script>
