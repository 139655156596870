<template>
   <v-card elevation="2" class="mb-2 mr-2" style="width: 100%" :disabled="!$store.getters.isSuperOrAdmin" :loading="isloading">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog message="¿Regenerar API Key?" ref="dialog" @result_ok="save" />
      <v-card-title>
         API Key
         <v-icon small right>mdi-key-variant</v-icon>
      </v-card-title>
      <v-card-text>
         <form>
            <v-text-field
               outlined
               :value="$store.state.perfil.api_key_sync"
               readonly
               prepend-icon="mdi-cached"
               append-icon="mdi-content-copy"
               @click:prepend="$refs.dialog.Show()"
               @click:append="copy"
            ></v-text-field>
         </form>
      </v-card-text>
   </v-card>
</template>

<script>
import Notification from "../../Notification.vue";
import Dialog from "../../Dialog.vue";

export default {
   components: {
      Notification,
      Dialog,
   },
   data: () => {
      return {
         isloading: false,
      };
   },
   methods: {
      async save() {
         try {
            this.isloading = true;
            let res = await this.$store.dispatch("updateAPIKEY", {});
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async copy() {
         await navigator.clipboard.writeText(this.$store.state.perfil.api_key_sync);
      },
   },
};
</script>
