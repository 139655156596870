<template>
   <div>
      <v-card elevation="2" class="" width="100%" :loading="isloading" :disabled="!$store.getters.isSuperOrAdmin">
         <Notification type="success" message="Cambios guardados" ref="ok_noti" />
         <Notification type="error" message="Error" ref="error_noti" />
         <v-card-title>
            Servidor de correo
            <v-icon small right>mdi-email</v-icon>
         </v-card-title>
         <v-card-text>
            <form style="width: 100%">
               <v-text-field
                  v-model="smtp"
                  :error-messages="smtpErrors"
                  label="SMTP"
                  required
                  @input="$v.smtp.$touch()"
                  @blur="$v.smtp.$touch()"
               ></v-text-field>
               <v-select
                  v-model="protocolo"
                  :items="protocolos"
                  :error-messages="protocoloErrors"
                  label="Protocolo"
                  required
                  @change="$v.protocolo.$touch()"
                  @blur="$v.protocolo.$touch()"
               ></v-select>
               <v-text-field
                  v-model="remitente_email"
                  :error-messages="remEmailErrors"
                  label="Remitente Email"
                  required
                  @input="$v.remitente_email.$touch()"
                  @blur="$v.remitente_email.$touch()"
               ></v-text-field>
               <v-text-field
                  v-model="remitente_pass"
                  :error-messages="remPassErrors"
                  label="Remitente Contraseña"
                  required
                  @input="$v.remitente_pass.$touch()"
                  @blur="$v.remitente_pass.$touch()"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
               ></v-text-field>
               <v-text-field
                  v-model="puerto"
                  :error-messages="puertoErrors"
                  label="Puerto"
                  required
                  @input="$v.puerto.$touch()"
                  @blur="$v.puerto.$touch()"
               ></v-text-field>
               <v-btn class="mr-4" @click="submit" :loading="isloading">Guardar</v-btn>
               <v-btn @click="clear" x-small icon>
                  <v-icon dense>mdi-eraser</v-icon>
               </v-btn>
            </form>
         </v-card-text>
      </v-card>
   </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, integer, email } from "vuelidate/lib/validators";
import Notification from "../Notification.vue";

export default {
   mixins: [validationMixin],
   validations: {
      smtp: { required, maxLength: maxLength(50) },
      protocolo: { required },
      remitente_email: { required, maxLength: maxLength(50), email },
      remitente_pass: { required, maxLength: maxLength(50) },
      puerto: { required, maxLength: maxLength(10), integer },
   },
   components: {
      Notification,
   },
   data: () => ({
      smtp: "",
      protocolo: "",
      protocolos: ["SSL", "TLS"],
      remitente_email: "",
      remitente_pass: "",
      puerto: "",
      show_password: false,
      isloading: false,
   }),
   mounted: function () {
      this.smtp = this.$store.state.perfil.inf_email.smtp;
      this.protocolo = this.$store.state.perfil.inf_email.protocolo;
      this.remitente_email = this.$store.state.perfil.inf_email.remitente_email;
      this.puerto = this.$store.state.perfil.inf_email.puerto;
   },
   computed: {
      smtpErrors() {
         const errors = [];
         if (!this.$v.smtp.$dirty) return errors;
         !this.$v.smtp.required && errors.push("El smtp es requerido");
         !this.$v.smtp.maxLength && errors.push("El maximo de caracteres es 50");
         return errors;
      },
      protocoloErrors() {
         const errors = [];
         if (!this.$v.protocolo.$dirty) return errors;
         !this.$v.protocolo.required && errors.push("El protocolo es requerido");
         return errors;
      },
      remEmailErrors() {
         const errors = [];
         if (!this.$v.remitente_email.$dirty) return errors;
         !this.$v.remitente_email.required && errors.push("Requerido.");
         !this.$v.remitente_email.maxLength && errors.push("Maximo 50 caracteres");
         !this.$v.remitente_email.email && errors.push("El correo no es valido");
         return errors;
      },
      remPassErrors() {
         const errors = [];
         if (!this.$v.remitente_pass.$dirty) return errors;
         !this.$v.remitente_pass.required && errors.push("Requerido.");
         !this.$v.remitente_pass.maxLength && errors.push("Maximo 50 caracteres");
         return errors;
      },
      puertoErrors() {
         const errors = [];
         if (!this.$v.puerto.$dirty) return errors;
         !this.$v.puerto.required && errors.push("Requerido.");
         !this.$v.puerto.maxLength && errors.push("Maximo 10 caracteres");
         !this.$v.puerto.integer && errors.push("Debe ser un numero entero");
         return errors;
      },
   },

   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("updateEmail", {
                  smtp: this.smtp,
                  email: this.remitente_email,
                  pass: this.remitente_pass,
                  puerto: this.puerto,
                  protocolo: this.protocolo,
               });
               if (res == true) {
                  this.$refs.ok_noti.Show();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.smtp = "";
         this.protocolo = "";
         this.remitente_email = "";
         this.remitente_pass = "";
         this.puerto = "";
      },
   },
};
</script>
