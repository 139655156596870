<template>
   <v-card elevation="2" class="mb-2 mr-2" style="width: 100%; max-width: 400px" :loading="isloading">
      <Notification type="success" message="Contraseña actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card-title>
         Actualizar contraseña
         <v-icon small right>mdi-lock</v-icon>
      </v-card-title>
      <v-card-text>
         <form>
            <v-text-field
               v-model="password"
               :error-messages="passwordErrors"
               label="Contraseña"
               required
               @input="$v.password.$touch()"
               @blur="$v.password.$touch()"
               :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
               :type="show_password ? 'text' : 'password'"
               @click:append="show_password = !show_password"
            ></v-text-field>
            <v-text-field
               v-model="repeat"
               :error-messages="repeat_passwordErrors"
               label="Confirmar contraseña"
               required
               @input="$v.repeat_password.$touch()"
               @blur="$v.repeat_password.$touch()"
               :append-icon="show_repeat ? 'mdi-eye' : 'mdi-eye-off'"
               :type="show_repeat ? 'text' : 'password'"
               @click:append="show_repeat = !show_repeat"
            ></v-text-field>
            <v-btn class="mr-4" @click="submit" :loading="isloading">Guardar</v-btn>
            <v-btn @click="clear" x-small icon>
               <v-icon dense>mdi-eraser</v-icon>
            </v-btn>
         </form>
      </v-card-text>
   </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, sameAs } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      password: {
         required,
         minLength: minLength(10),
         maxLength: maxLength(30),
      },
      repeat_password: {
         sameAsPassword: function () {
            if (this.password == this.repeat) {
               return true;
            } else {
               return false;
            }
         },
      },
   },
   data: () => ({
      password: "",
      repeat: "",
      show_password: false,
      show_repeat: false,
      isloading: false,
   }),

   computed: {
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         !this.$v.password.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.password.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      repeat_passwordErrors() {
         const errors = [];
         if (!this.$v.repeat_password.$dirty) return errors;
         !this.$v.repeat_password.sameAsPassword && errors.push("Las contraseñas no son iguales");
         return errors;
      },
   },

   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("updatePassword", {
                  password: this.password,
               });
               if (res == true) {
                  this.$refs.ok_noti.Show();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.clear();
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.password = "";
         this.repeat_password = "";
      },
   },
};
</script>
